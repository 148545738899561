import React from 'react'
import ReactDOM from 'react-dom'
import './style/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from "./redux/store/index"
import { Provider } from "react-redux"

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
